@import url("https://fonts.googleapis.com/css?family=Inter:regular,medium,bold,black&display=swap");
@import url("https://fonts.googleapis.com/css?family=Crimson+Pro:regular,medium,bold,black&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:regular,medium,bold,black&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Inter',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: #F5F5F5;
  
}

h2{
  font-size: 2.1rem;
  font-weight: 700;
  font-family: Crimson Pro, serif;
}

h3, h4 h5{
  font-size: 1.4rem;
  font-weight: 700;
  font-family: Crimson Pro, serif;
}

.website > h3{
  font-size: 1.8rem;
  font-weight: 700;
  font-family: Crimson Pro, serif;

}

@media only screen and (max-width:480px){
  h2, h3, h4 h5{
    font-size: 1.5rem;
    font-weight: 700;
    font-family: Crimson Pro, serif;
  }
  h2 > span, h3 > span, h4 > span, h5 > span{
    margin-top: 2px;
    font-size: 1.3rem;
    font-weight: 700;
    font-family: Crimson Pro, serif;
  }

  
.website > h3{
  font-size: 1.3rem;
  font-weight: 700;
  font-family: Crimson Pro, serif;

}

  
}

.web-img{
  animation-name: float;
  animation-duration: 3s;
  transition: ease-in;
}

@keyframes float{
  0%{transform:translateY(0px);}
  50%{transform: translateY(8px);}
  100%{transform: translateY(0px);}

}



/**ALE VECTOR**/
.eye{
}

.svg{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.svg-container{
  position: relative;
  width: 100%;
  height: 400px;
}

#pupil{
  transform-origin: center;

}


#mail{
  writing-mode: vertical-lr;
}

.others::before {
  content: '';
  width: 0;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.others > *:first-child{
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 120px; /* If you need scroll offset try this magic! */
}

::-webkit-scrollbar{
  width: 12px;
  height: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 30px;
  margin-right: 9px;
  box-shadow: inset 0 0 10px 10px #8f8f8f;
    border: solid 4px transparent;

}

@layer base{  
  .dropMenuOff{
    @apply py-4 sm:py-8;
  } 
  
  .dropMenuOnUp{
    @apply bg-primary/60 backdrop-blur-md py-4 sm:py-6 -translate-y-[85px];
  }

  .dropMenuOnDown{
    @apply bg-primary/60 backdrop-blur-md drop-shadow-md py-4 sm:py-6 translate-y-0;
  }

  .itemsScroll{
    width: calc(100% + 50px);
  }



}

